import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject, combineLatest, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActiveGymFacade } from 'src/store/active-gym/active-gym.facade';
import { GymDBDocType } from '../common/doc-types';
import { GymInfoDoc } from '../common/docs';
import { GymListEntry } from '../model/gym-list-entry';
import { BBStorage } from '../utils/bb-storage';
import DateUtils from '../utils/date-utils';
import { CouchService, SyncDirection } from './couch.service';
import { DbChangesService } from './db.changes.service';
import { DocWriteService } from './doc.write.service';
import { GymService } from './gym.service';
import { PouchDBService } from './pouch-db.service';
import { PouchDocService } from './pouch-doc.service';

@Injectable()
export class PouchGymService implements GymService {


  constructor(
    private dbChangeService: DbChangesService,
    private pouchDocService: PouchDocService,
    private logger: NGXLogger,
    private bbStorage: BBStorage,
    private pouchDBService: PouchDBService,
    private docWriteService: DocWriteService,
    private activeGymFacade: ActiveGymFacade,
    private couchService: CouchService) {
  }

  get gymEvents(): Subject<string> {
    return this._gymEvents;
  }

  private _gymEvents = new Subject<string>();

  private static infoDocToEntry(gymInfoDoc: GymInfoDoc): GymListEntry {
    let isNewGym;
    if (gymInfoDoc.timeCreated) {
      const creationDate = new Date(gymInfoDoc.timeCreated);
      isNewGym = DateUtils.ageInDays(creationDate) < 7;
    } else {
      isNewGym = false;
    }
    return new GymListEntry(
      isNewGym, gymInfoDoc.timeCreated ? new Date(gymInfoDoc.timeCreated) : null,
      gymInfoDoc._id, gymInfoDoc.gymName, gymInfoDoc.gymAdmin, gymInfoDoc.city, gymInfoDoc.country, gymInfoDoc.address, gymInfoDoc.isPrivate, gymInfoDoc.gymRules, gymInfoDoc.gymAdmins);
  }

  public getLocalGyms$(): Observable<GymListEntry[]> {
    return this.gymInfoDocsToListEntries(this.pouchDocService.getLocalGymInfoDocs$());
  }

  public getLocalGym$(gymId: string): Observable<GymListEntry> {
    return this.gymInfoDocToListEntry(this.pouchDocService.getGymInfoDoc$(gymId));
  }

  public async removeRemoteGym(gymId: string) {
    await this.couchService.removeRemoteGymInfoDoc(gymId);
  }

  public getRemoteGymsThatAreNotLocalYet$(): Observable<GymListEntry[]> {
    const gymDocInfosThatAreNotLocalYet$ = combineLatest(
      this.couchService.getRemoteGymInfoDocs$(), this.pouchDocService.getLocalGymInfoDocs$())
      .pipe(
        map(([remote, local]) => {
          const localIds = local.map(l => l._id);
          return remote.filter(r => localIds.indexOf(r._id) < 0);
        })
      );
    return this.gymInfoDocsToListEntries(gymDocInfosThatAreNotLocalYet$);
  }

  public getGymListEntry$(gymId: string): Observable<GymListEntry> {
    return this.couchService.getRemoteGymInfoDoc$(gymId).pipe(map(gymInfoDoc => PouchGymService.infoDocToEntry(gymInfoDoc)));
  }

  public async getGymListEntryFromPouch$(gymId: string): Promise<GymListEntry> {
    await this.syncActiveGym();
    const gymInfoDoc: GymInfoDoc = await firstValueFrom(this.pouchDocService.getDocOfTypeWithId$<GymInfoDoc>(
      gymId, GymDBDocType.GYM_INFO, gymId));
    return PouchGymService.infoDocToEntry(gymInfoDoc);
  }

  public async isLocalGym(gymId: string): Promise<boolean> {
    const localGymIds = await firstValueFrom(this.pouchDocService.getLocalGymIds$());
    return localGymIds.includes(gymId);
  }

  public getActiveGymId$() {
    return this.activeGymFacade.activeGymId$;
  }

  public async addLocalGym(gymId: string) {
    await this.pouchDBService.addGymDatabase(gymId);
    await this.dbChangeService.resetUpdateSeq();
  }

  public async deleteGym(gymId: string) {
    await this.pouchDBService.deleteGymDatabase(gymId);
    const activeGymId = await firstValueFrom(this.getActiveGymId$());
    if (activeGymId === gymId) {
      const localGyms = await firstValueFrom(this.getLocalGyms$());
      if (localGyms.length === 0) {
        this.activeGymFacade.activeGymChanged(null);
      } else {
        // we arbitrarily set the first gym in the list to be the active one
        this.activeGymFacade.activeGymChanged(localGyms[0]);
      }
    }
  }

  public async syncActiveGym() {
    const syncDirection = await this.computeSyncDirection();
    if (!syncDirection) {
      this.logger.debug('Skipping gym sync as everything seems to be up-to-date');
      return;
    }
    const start = performance.now();
    const activeGymId = await firstValueFrom(this.getActiveGymId$());
    await this.pouchDBService.syncGymDatabase(activeGymId, syncDirection);
    this.logger.debug(`syncGymDatabase ${syncDirection} took ${performance.now() - start}`);
    await this.dbChangeService.updateUpdateSeq();
  }

  private async computeSyncDirection(): Promise<SyncDirection> {
    const couchHasChanged = await this.dbChangeService.couchHasChanged();
    const pouchHasChanged = await this.dbChangeService.pouchHasChanged();
    if (!couchHasChanged && !pouchHasChanged) {
      return null;
    }
    return couchHasChanged && pouchHasChanged ? 'both' : couchHasChanged ? 'from-server' : 'to-server';
  }

  private gymInfoDocsToListEntries(gymInfoDocs$: Observable<GymInfoDoc[]>): Observable<GymListEntry[]> {
    return gymInfoDocs$
      .pipe(
        map(gymInfoDocs => gymInfoDocs.map(gymInfoDoc => PouchGymService.infoDocToEntry(gymInfoDoc))
        ));
  }

  private gymInfoDocToListEntry(gymInfoDoc$: Observable<GymInfoDoc>): Observable<GymListEntry> {
    return gymInfoDoc$
      .pipe(
        map(gymInfoDoc => gymInfoDoc ? PouchGymService.infoDocToEntry(gymInfoDoc) : null)
      );
  }

  public async setActiveGymId(gymId: string) {
    const localGymIds = (await firstValueFrom(this.getLocalGyms$())).map(entries => entries.gymId);
    if (gymId == null && localGymIds.length > 0) {
      throw new Error('Cannot unset active gym id unless there are no local gyms');
    }
    if (gymId != null && localGymIds.indexOf(gymId) < 0) {
      throw new Error('Cannot set active gym id to gym that does not exist');
    }
    await this.bbStorage.resetActiveGymCache();
  }

  async updateActiveGym(gymName, city, country, isPrivate, gymRules, gymAdmins: string[]): Promise<GymInfoDoc> {
    const activeGymId = await firstValueFrom(this.getActiveGymId$());
    const gymInfoDoc: GymInfoDoc = await firstValueFrom(this.pouchDocService.getDocOfTypeWithId$<GymInfoDoc>(
      activeGymId, GymDBDocType.GYM_INFO, activeGymId));
    const updatedGymInfoDoc = {
      ...gymInfoDoc,
      gymName: gymName,
      city: city,
      country: country,
      isPrivate: isPrivate,
      gymRules: gymRules,
      gymAdmins: gymAdmins
    };
    await this.docWriteService.updateDocument(updatedGymInfoDoc);
    await this.syncActiveGym();
    return updatedGymInfoDoc;
  }

}

