import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AlertController, LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/services/auth.service';
import { GradeService } from 'src/services/grade.service';
import { GymDBDocType } from '../../common/doc-types';
import { Hold } from '../../common/docs';
import { EditWallCurrentnessComponent } from '../../components/edit-wall-currentness/edit-wall-currentness.component';
import { BoulderCreationError } from '../../errors/bb-error';
import { Boulder } from '../../model/boulder';
import { Color } from '../../model/color';
import { EditableBoulder } from '../../model/editable-boulder';
import { Wall } from '../../model/wall';
import { WallImage } from '../../model/wall-image';
import { AscentService } from '../../services/ascent.service';
import { BoulderService } from '../../services/boulder.service';
import { DifficultyRatingService } from '../../services/difficulty-rating.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { ImageService } from '../../services/image.service';
import { SchemaService } from '../../services/schema.service';
import { ValidationService } from '../../services/validation.service';
import { WallService } from '../../services/wall.service';
import { BBStorage } from '../../utils/bb-storage';
import ColorUtils from '../../utils/color-utils';
import { WallResetPage } from '../wall-reset/wall-reset.page';
import { WallsPage } from '../walls/walls.page';
import { CreateBoulderHelpComponent } from './create-boulder-help.component';
import { CreateBoulderStep } from './create-boulder-step';
import { ContactGymAdminComponent } from 'src/components/contact-gym-admin-dialog/contact-gym-admin.component';
import { GymService } from 'src/services/gym.service';
import { ActiveGymFacade } from 'src/store/active-gym/active-gym.facade';

@Component({
    selector: 'app-create-boulder',
    templateUrl: './create-boulder.page.html',
    providers: [],
    styleUrls: ['./create-boulder.page.scss'],
})
export class CreateBoulderPage implements OnInit {

    isGymAdmin = false;

    walls: Wall[];
    Step = CreateBoulderStep;
    possibleGrades: number[];
    currentStep: CreateBoulderStep = CreateBoulderStep.CHOOSE_WALL;

    supportedFootholdColors: Color[];

    isCampusBoulder = false;
    isPrivateBoulder = false;
    newBoulder = new EditableBoulder();
    boulderToEdit: Boulder;

    private dbHasChanged = false;
    readonly boulderName: FormControl;
    readonly boulderNameFormGroup: FormGroup;

    constructor(
        private gradeService: GradeService,
        private toastController: ToastController,
        private loadingCtrl: LoadingController,
        private ratingService: DifficultyRatingService,
        private alertController: AlertController,
        private ascentService: AscentService,
        private bbStorage: BBStorage,
        private authService: AuthService,
        private router: NavController,
        private modalCtrl: ModalController,
        private modalController: ModalController,
        private schemaService: SchemaService,
        private validationService: ValidationService,
        private wallService: WallService,
        private errorHandler: ErrorNotificationService,
        public imageService: ImageService,
        private boulderService: BoulderService,
        private translateService: TranslateService,
        private gymService: GymService,
        private gymFacade: ActiveGymFacade
    ) {

        this.possibleGrades = this.gradeService.getCurrentGradeSystem().possibleGrades();
        this.supportedFootholdColors = ColorUtils.getSupportedFootholdColors();
        this.boulderName = new FormControl('', this.validationService.getFormValidator(this.schemaService.docSchema(GymDBDocType.BOULDER), 'boulderName'));
        this.boulderNameFormGroup = new FormGroup({
            bolderName: this.boulderName
        });
    }

    ngOnInit(): void {
        this.gymFacade.activeGym$.subscribe(activeGym => {
            this.gymService.getGymListEntryFromPouch$(activeGym.gymId).then(gym => {
                this.isGymAdmin = this.authService.isGymAdmin(gym);
            });
        });
        this.initialize();
    }

    private async initialize() {
        await this.loadWalls();
        if (this.boulderToEdit) {
            this.currentStep = CreateBoulderStep.DEFINE_HOLDS;
            this.newBoulder = new EditableBoulder(this.boulderToEdit);
            this.isPrivateBoulder = this.newBoulder.isPrivate;
            this.ratingService.getRatingDocForCurrentUser$(this.boulderToEdit.boulderId).subscribe(rating => {
                this.newBoulder.proposedGrade = rating.difficulty;
            });
        } else {
            this.newBoulder = new EditableBoulder();
            this.currentStep = CreateBoulderStep.CHOOSE_WALL;
        }
        this.boulderName.setValue(this.newBoulder.boulderName);
    }

    showNextButton() {
        return CreateBoulderStep.hasNext(this.currentStep) && !this.stepIsDisabled(CreateBoulderStep.next(this.currentStep));
    }

    startHoldsMissing() {
        return !this.newBoulder.startIsDefined();
    }

    topHoldMissing() {
        return !this.newBoulder.topIsDefined();
    }

    goToPreviousSlide() {
        this.currentStep--;
    }

    goToNextSlide() {
        this.currentStep = CreateBoulderStep.next(this.currentStep);
        this.stepChanged();
    }

    onHoldsChanged(holds: Hold[]) {
        this.newBoulder.holds = holds;
    }

    onEnumerateHoldsChanged(enumerateHolds: boolean) {
        this.newBoulder.enumerateHolds = enumerateHolds;
    }

    async reset() {

        if (!this.isGymAdmin) {
            await this.showContactGymAdminDialog();
            return;
        }

        const modal = await this.modalCtrl.create({
            component: WallResetPage,
            id: 'WallResetPageModal'
        }
        );
        modal.onDidDismiss().then(async () => {
            this.dbHasChanged = true;
            await this.loadWalls();
        });
        await modal.present();
    }

    private async showContactGymAdminDialog() {
        const modal = await this.modalCtrl.create({
            component: ContactGymAdminComponent
        });
        await modal.present();
    }

    async manageWalls() {

        if (!this.isGymAdmin) {
            await this.showContactGymAdminDialog();
            return;
        }

        const modal = await this.modalCtrl.create({
            component: WallsPage,
            id: 'WallsPageModal'
        }
        );
        modal.onDidDismiss().then(async (data) => {
            this.dbHasChanged = true;
            await this.loadWalls();
            if (data.data) {
                this.chooseWallImage(data.data);
            }
        });
        await modal.present();
    }

    async editWall(wall: Wall, currentStep = 1) {
        const modal = await this.modalController.create({
            component: EditWallCurrentnessComponent,
            componentProps: { 'wall': wall, 'currentStep': currentStep }
        }
        );
        modal.onDidDismiss().then(async data => {
            await this.loadWalls();
            if (data.data) {
                this.dbHasChanged = true;
                this.chooseWallImage(data.data);
            }
        });
        await modal.present();
    }


    async persistBoulder() {
        const loader = await this.loadingCtrl.create({
            message: this.translateService.instant('shared.please-wait')
        });
        await loader.present();
        try {
            this.newBoulder.boulderName = this.boulderName.value;
            const boulderId = await this.boulderService.addOrUpdateBoulder(this.newBoulder);
            if (!this.boulderToEdit && !this.isPrivateBoulder) {
                await this.sentIt(boulderId);
            } else {
                await this.closeWizard(true);
            }
        } catch (error) {
            await this.errorHandler.showBBError(new BoulderCreationError(error));
            await this.closeWizard(true);
        } finally {
            await loader.dismiss();
        }
    }

    async sentIt(boulderId: string) {
        const alert = await this.alertController.create({
            backdropDismiss: false,
            header: this.translate('did-you-send'),
            inputs: [
                {
                    name: 'redpoint',
                    type: 'radio',
                    label: this.translate('yes'),
                    value: 'redpoint',
                    checked: true
                },
                {
                    name: 'flash',
                    type: 'radio',
                    label: this.translate('yes-flash'),
                    value: 'flash'
                },
                {
                    name: 'no',
                    type: 'radio',
                    label: this.translate('no'),
                    value: 'no'
                }
            ],
            buttons: [
                {
                    text: 'Ok',
                    id: 'ok-button',
                    handler: async (data) => {
                        if (data == 'flash') {
                            await this.ascentService.createOrUpdateAscentType(boulderId, 'flash');
                        } else if (data == 'redpoint') {
                            await this.ascentService.createOrUpdateAscentType(boulderId, 'redpoint');
                        }
                        await this.closeWizard(true);
                    }
                }
            ]
        });
        await alert.present();
    }

    async close() {
        await this.closeWizard(this.dbHasChanged);
    }

    async dismiss() {
        await this.modalCtrl.dismiss();
    }

    async closeWizard(reload: boolean) {
        this.dismiss();
        await this.router.navigateRoot('/tabs/boulder-list', { queryParams: { reload: reload } });
    }


    async chooseWallImage(wallImage: WallImage) {
        await this.loadWalls();
        this.newBoulder.wallId = this.walls.filter(w => w.wallImages.filter(i => i.imageId === wallImage.imageId).length > 0)[0].wallId;
        this.newBoulder.wallImage = wallImage;
        this.currentStep = CreateBoulderStep.DEFINE_HOLDS;
    }

    wallClicked(wall: Wall) {
        this.newBoulder.wallId = wall.wallId;
        const wallImages = wall.wallImages.filter(image => image.currentness === 'current');
        if (wallImages.length > 0) {
            this.newBoulder.wallImage = wallImages[0];
            this.currentStep = CreateBoulderStep.DEFINE_HOLDS;
            this.stepChanged();
        } else {
            this.editWall(wall, 3);
        }
    }

    async showHelp() {
        const modal = await this.modalCtrl.create({
            component: CreateBoulderHelpComponent
        }
        );
        await modal.present();
    }

    stepIsDisabled(step: CreateBoulderStep): boolean {
        const imageChosen = this.newBoulder.wallImage;
        const boulderIsValid = this.newBoulder.isValid();
        const gradeDefined = this.newBoulder.proposedGrade > -1;

        if (!imageChosen) {
            return true;
        }
        if (!boulderIsValid && step > CreateBoulderStep.DEFINE_HOLDS) {
            return true;
        }
        if (boulderIsValid && step < CreateBoulderStep.NAME) {
            return false;
        }
        return !gradeDefined && step > CreateBoulderStep.GRADE;
    }

    private async loadWalls() {
        this.walls = [];
        const allBoulders = await firstValueFrom(this.boulderService.getBoulders$());
        const walls = await firstValueFrom(this.wallService.loadWalls$());
        const numBouldersByWallId: Map<string, number> = new Map<string, number>();
        allBoulders.forEach(b => {
            const numBoulders = numBouldersByWallId.get(b.wallId) ?? 0;
            numBouldersByWallId.set(b.wallId, numBoulders + 1);
        });
        this.walls = walls.sort((a, b) => {
            const countA = numBouldersByWallId.get(a.wallId);
            const countB = numBouldersByWallId.get(b.wallId);
            if (countA && countB) {
                return countA > countB ? -1 : 1;
            } else if (countA) {
                return -1;
            } else if (countB) {
                return 1;
            } else {
                return a.wallName > b.wallName ? 1 : -1;
            }
        });
    }

    isValid() {
        return this.boulderNameFormGroup.valid;
    }

    private stepChanged() {
        if (this.currentStep == CreateBoulderStep.DEFINE_HOLDS && this.bbStorage.firstBoulderCreation) {
            this.showHelp();
            this.bbStorage.firstBoulderCreation = false;
        }
    }

    gradeSelected(grade: any) {
        this.newBoulder.proposedGrade = grade;
        this.goToNextSlide();
    }

    footholdColorChanged(color: Color) {
        const index = this.newBoulder.footholdConfig.allowedColors.indexOf(color.id);
        if (index > -1) {
            this.newBoulder.footholdConfig.allowedColors.splice(index, 1);
        } else {
            this.newBoulder.footholdConfig.allowedColors.push(color.id);
        }
        this.updateCampusBoulder();
    }

    async deleteBoulderButtonClicked() {
        const alert = await this.alertController.create({
            header: this.translate('delete-boulder'),
            buttons: [
                {
                    text: this.translate('not-delete'),
                    role: 'cancel',
                    cssClass: 'secondary'
                },
                {
                    text: this.translate('yes-delete'),
                    handler: async () => {
                        await this.deleteBoulder();
                    }
                }
            ]
        });
        await alert.present();
    }

    private async deleteBoulder() {
        const loader = await this.loadingCtrl.create({
            message: this.translateService.instant('shared.please-wait')
        });
        await loader.present();
        try {
            await this.boulderService.deleteBoulder(this.boulderToEdit.boulderId);
            await this.modalController.dismiss();
            await this.modalController.dismiss({ isDirty: true }, '', 'boulder-details-modal');
        } finally {
            loader.dismiss();
        }
    }


    showBackButton() {
        return this.currentStep != CreateBoulderStep.CHOOSE_WALL && (this.currentStep != CreateBoulderStep.DEFINE_HOLDS || !this.boulderToEdit);
    }

    public translate(key) {
        return this.translateService.instant('create-boulder-page.' + key);
    }

    async onCampusToggleChanged() {
        if (this.isCampusBoulder) {
            if (this.boulderHasSpecificFootholds()) {
                const toast = await this.toastController.create({
                    header: this.translate('no-campus-boulder'),
                    message: this.translate('footholds-already-defined'),
                    icon: 'information-circle',
                    position: 'top',
                    color: 'primary',
                    buttons: [
                        {
                            text: this.translateService.instant('shared.ok'),
                            role: 'cancel',
                            handler: () => {
                                this.isCampusBoulder = false;
                            }
                        }
                    ]
                });
                await toast.present();
            } else {
                this.newBoulder.footholdConfig.allowedColors = [];
                this.newBoulder.footholdConfig.withSpax = false;
                this.newBoulder.footholdConfig.withHandholds = false;
                this.newBoulder.footholdConfig.allHoldsAllowed = false;
                this.newBoulder.footholdConfig.withSmallFootholds = false;
            }
        }
    }

    private boulderHasSpecificFootholds() {
        return this.newBoulder.holds.filter(h => h.holdType === 'foot').length > 0;
    }

    updateCampusBoulder() {
        if (
            this.boulderHasSpecificFootholds() ||
            this.newBoulder.footholdConfig.allowedColors.length > 0 ||
            this.newBoulder.footholdConfig.withSpax ||
            this.newBoulder.footholdConfig.withHandholds ||
            this.newBoulder.footholdConfig.withSmallFootholds ||
            this.newBoulder.footholdConfig.allHoldsAllowed
        ) {
            this.isCampusBoulder = false;
        }
    }

    public onPrivateToggleChanged() {
        this.newBoulder.isPrivate = this.isPrivateBoulder;
    }
}
